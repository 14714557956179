main {
  overflow-y: scroll;
}
.layout {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 80px 1fr;
  height: 100vh;
}
.aside {
  display: block;
  background-color: white;
}
.navbar {
  background-color: white;
  box-shadow: 0px 0px 10px;
  grid-column: 1 / span 4;
  grid-row: 1;
  z-index: 200;
}

.offcanvas {
  width: auto ;
}

@media (max-width: 991.98px) {
  .layout {
    display: grid;
    grid-template-columns: 1fr;
  }
  .aside {
    display: none;
  }
  .meter_command_floating {
    width: 100%;
  }
  .chevron-icon {
    display: none;
  }
}
.chevron-icon {
  position: absolute;
  bottom: 5px;
  transition: all 0.5s ease;
}

.chevron-right {
  left: 55px;
}

.chevron-left {
  left: 220px;
}
