.loader {
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #db5b34; /* Blue */
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
  display: flex;
  align-items: center;
  justify-content: center;
}

.super-center {
  display: grid ;
  place-items: center ;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* Loader.css */
@keyframes custom-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.custom-spinner {
  animation: custom-spin 1s linear infinite;
}
